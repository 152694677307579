export const environment = {

  production: true,
  proyecto: "intranet-cli8",
  urlContenido: 'https://d23b2ob1czfwts.cloudfront.net/#/contenidos/',
  cryptoKey: "EQ1P0GF4D3V",
  urlIntranet: "https://intranet-gfa.primeraplus.com.mx/",
  urlCorreo: "https://intranet-gfa.primeraplus.com.mx/#/usuarios/registro/",
  urlErpUsuario: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/cargarUsuarioErp/",
  urlEmpresas: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerEmpresas",

  bitacoras: {
    getTokenBitacoras: {
      Dev: "U2FsdGVkX1/LUfioT1dDJ+YV+JnBB5NI5EQlI+6n2SsRTXhjrwFqJqXCEB6XoJ9hhtfMYribmiRhpzSJKrMwNOYswY2q3RFAHM/gUg7aQ9Y=",
      QA: "U2FsdGVkX1/LUfioT1dDJ+YV+JnBB5NI5EQlI+6n2SsRTXhjrwFqJqXCEB6XoJ9hhtfMYribmiRhpzSJKrMwNOYswY2q3RFAHM/gUg7aQ9Y=",
      Prod: "U2FsdGVkX18xfZHduMd9Afo9oCCXVMLzGdYWs4ulz4sMGbJ+u6LfLJJaeNkgFIR9RWHCXbN8sZvsnSRkg1PeZ3u3R7np072V/UDIb6k2+bw=",
    },
    getUrlBitacoras: {
      Dev: "https://4dkdt59jv8.execute-api.us-east-1.amazonaws.com/dev/bitacoras/",
      QA: "https://4dkdt59jv8.execute-api.us-east-1.amazonaws.com/dev/bitacoras/",
      Prod: "https://4dkdt59jv8.execute-api.us-east-1.amazonaws.com/dev/bitacoras/"
    },

  },
  clave: {
    BlogsDev: "U2FsdGVkX1+eFF7BLp2LHHW73aSIlawDP5ZUXPi7BAasCJc47343FKrAn8o1F3BpqaXE8ukWf2J9TatIbYq5eg==",
    CoordinadosDev: "U2FsdGVkX180l6JYmLn/SyBasTyqKhGxLO2TLcWsHbhP5Hkvf/Rp4nm+Ud3X5jhtjz3H2Dhf70jZG/DngSbRXNFvjQhaWghnYQ5YoCqj9ss=",
    CoordinadosProd: "U2FsdGVkX1+ZLYbyQwYS0bs0g78PfdU4tSGAcKbCwHV2Fz02sudcMn4P7vGgUyr0NdC5/QWDBDCLzLr6OgqOpNPv5XW6vLHOrzkRtr1rnxk=",
    FacturacionEmisionDev: "U2FsdGVkX19VldF/pu1xZdctAek9WRZYwmwFn1/1liZO/Wv7tSFsfkgARVta4y6pFQIPEaVlKmUHTA8/BuoCVv202Gb1ZymjzapV1D2mT2GYbsafcbe+yKQ5XV6XSACy",
    FacturacionEmisionQA: "U2FsdGVkX19VldF/pu1xZdctAek9WRZYwmwFn1/1liZO/Wv7tSFsfkgARVta4y6pFQIPEaVlKmUHTA8/BuoCVv202Gb1ZymjzapV1D2mT2GYbsafcbe+yKQ5XV6XSACy",
    FacturacionEmisionProd: "U2FsdGVkX18xfZHduMd9Afo9oCCXVMLzGdYWs4ulz4sMGbJ+u6LfLJJaeNkgFIR9RWHCXbN8sZvsnSRkg1PeZ3u3R7np072V/UDIb6k2+bw=",
    RollBitsDev: "U2FsdGVkX1/N/ZB9xT4pE1y+XILIr8ooSQyRxUxMgboJkwpKCU6eoVKzX9WM5Mm4CQWRnJWqp76eoVb0bFH2G5f2qczYNcsd1bEG5knlhuk=",
    RollBitsQA: "U2FsdGVkX1+niQtnw+wWN9lYh67T3TGdpynMuaXhxozkBQldLneG5cxwlGJnFL67UEZ0hzGLK0JHxdcINr6WodatudRdienuhsxF3Lr8SRs=",
    RollBitsProd: "U2FsdGVkX19jdIrZLbVcvif1rcZIOH3/tp0dmVhlCdh3p5cF4nHHaRH1REPot/SWFd1ci62IHi19rOj0ZNZw5G9g3657xjeoN1Gv+pJ18ec=",
    PrimeraPlusDev: "U2FsdGVkX1+4N9VbVmeb1K9urnMY0zRrdShMvcuFCMAI7eFRlxF3CQzPr7CQyocn+ACvMf7gV7pg1t30ouRIt4POwYru3MnpwNKgLtyQZB5ovG1uNTT1pfR6kCqfQWRP/ZKIgI1nU8Q94ZmObU4ld/HetAMIDrE+fy7gOtQRD7wZvyjvCRAMjh+tbBEMp38ifUfSQTUkguFXwnNIVvcRhy7DFuqNJQyZYe+1hCrOj5zciyiUaCOwUBIGC1KqHaIN",
    PrimeraPlusProd: "U2FsdGVkX1+mmLysYwVRV8v0swVakR6VoT7MC4QTtrm9lQZXb/DAmSLVjgOsSUMDWLn/vA1Kzra1Qp4xB7J8Td4G8lrZFJBkDsV02qkI3NohAKwCaFzOxxYSUhlxvRhvBLiym0Ysz9KN9GZxBeQ9ywuKD6agU2RvzwjDNu19WtQGcV4fU/9LcCG03yiAWJ/QdsDJrjBpHxjoYrr6cIWAWnGAJ08A3wVisL1TtRv4gsxIiwZNRgkguOytNJ0BotWz",
    PrimeraPlusQA: "U2FsdGVkX1+/B72jrlF6goxTVD/oV2LWu2BvI4K++m3dFPDkVtvrC+3GCwbxYUXO25PJ1nE+PFOp3bTu5R6EKs8o/0jp377kOAcsmDjHOMysPSm+6YyHCp9Nvjs7Hgb27tt81CgdfqnpZ03SyQbAeDS5vh0yotCMS+1qn8DVyva6W+ttQTNK3VvipM5DCe3bs9SYZN91qQev6jTiIDpYIA==",
    SiemprePlusDev: "U2FsdGVkX19sWlUpatusYnHRRZTu5KjETt3Izw35dg8CWt/dlQmXiUEGYCFycU2PqpmIZVO5oRG3/iHAhMW2/H0VpPouOrvxdyJEhOOA1KE=",
    ConectaGFA: "U2FsdGVkX19YB7gWLKzyiktvXQzv6JNKBBD0kF+OrPpePCHKFJsExqS9MXOnERTiI/yeIoDnDmgH/ltgc0xCYKCxP2D7clPFsT07GfHutR4=",
    ConectaGFAProd: "U2FsdGVkX19YB7gWLKzyiktvXQzv6JNKBBD0kF+OrPpePCHKFJsExqS9MXOnERTiI/yeIoDnDmgH/ltgc0xCYKCxP2D7clPFsT07GfHutR4=",
    PaqueteriaDev: "U2FsdGVkX19LRshGm/XfuZRcCAA3lvC/yKsKDTFJeDsWAPrqrMeaKzSTHt5etjSTxYcKSLZFQCrDSrZJTrBkVNs7tUj8hddlR2q0c4o3gn8=",
    PaqueteriaProd: "U2FsdGVkX1/BTBLfbiHkha7QKFwZcwxtfiSe6utjkTxOdcfSK+E4k+LCqIyHszMXtbsmOtwelQQquFbjwTHWmduKGAB9pMbaFejhE2pqY9M=",
    TTURDev: "U2FsdGVkX1+RQDtSQge9KhrOuKNBaPoFUAv4zzHgFoDb4gBFEljVEsDNqWosNjBtTzipabxbFMDkipNaxE1VKw==",
    TTURProd: "U2FsdGVkX1+lco01yr2YTPyqS9VgIh0ejzFGHuur/fLishB6BTY+oQd2FH+Sy+DSrkxdva1jS+8Y5scGEGWhJwvuCxcuC4IxNEXAp3Gec9g=",
    FacturacionAdministracionDev:"U2FsdGVkX1+hUGgONToey3byLcp+JbUomnfT4pZM5evWcgOHCOlVlYoTr2aa+Vfx8+QysTzk86luZiRmlD0qZT72Q+tpGqyAmJRk4NwdnGP6D39ISm7UNkob0ywB+RxI",
    FacturacionAdministracionQA:"U2FsdGVkX19OQdIjyMdUtWgrJcEktyBQkm5gZPkpfOrfcR9do26jo49Pp7919wMxWf7t2Yd6K5ZgpZLE4pqih/2DM3tkL4ZOzNRvaWUfK+DqKNR82sBL2eoiPSeis1If",
    RefacturasDev:"U2FsdGVkX1+hUGgONToey3byLcp+JbUomnfT4pZM5evWcgOHCOlVlYoTr2aa+Vfx8+QysTzk86luZiRmlD0qZT72Q+tpGqyAmJRk4NwdnGP6D39ISm7UNkob0ywB+RxI",
    RefacturasProd:"U2FsdGVkX19qaSaZ1Mj4GufYpfR86LLbhd5zCtuud0Ie5snwkXSIkgm8NYZZ4shVPmVsloWKEsQxKaHfreWGeoK0LvKxUi61b4rJwUin5Pl7MsT+dZrfxPG1EoQWMzJ9",
    RefacturasQA:"U2FsdGVkX19OQdIjyMdUtWgrJcEktyBQkm5gZPkpfOrfcR9do26jo49Pp7919wMxWf7t2Yd6K5ZgpZLE4pqih/2DM3tkL4ZOzNRvaWUfK+DqKNR82sBL2eoiPSeis1If",
    CatalogosDev:"U2FsdGVkX1+hUGgONToey3byLcp+JbUomnfT4pZM5evWcgOHCOlVlYoTr2aa+Vfx8+QysTzk86luZiRmlD0qZT72Q+tpGqyAmJRk4NwdnGP6D39ISm7UNkob0ywB+RxI",
    CatalogosQA:"U2FsdGVkX19OQdIjyMdUtWgrJcEktyBQkm5gZPkpfOrfcR9do26jo49Pp7919wMxWf7t2Yd6K5ZgpZLE4pqih/2DM3tkL4ZOzNRvaWUfK+DqKNR82sBL2eoiPSeis1If",
    FacturacionAdministracionProd:"U2FsdGVkX19qaSaZ1Mj4GufYpfR86LLbhd5zCtuud0Ie5snwkXSIkgm8NYZZ4shVPmVsloWKEsQxKaHfreWGeoK0LvKxUi61b4rJwUin5Pl7MsT+dZrfxPG1EoQWMzJ9",
    CatalogosProd:"U2FsdGVkX19qaSaZ1Mj4GufYpfR86LLbhd5zCtuud0Ie5snwkXSIkgm8NYZZ4shVPmVsloWKEsQxKaHfreWGeoK0LvKxUi61b4rJwUin5Pl7MsT+dZrfxPG1EoQWMzJ9"
  },

  guardarContenido: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarContenido",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarContenido",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarContenido",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarContenido",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarContenido",
  },

  eliminarContenido: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarContenido",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarContenido",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarContenido",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarContenido",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarContenido",
  },

  cargarContenido: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenido",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenido",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenido",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenido",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenido",
  },

  cargarContenidos: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenidos",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenidos",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/cargarContenidos",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/cargarContenidos",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/cargarContenidos",
  },

  guardarImagen: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarImagen",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarImagen",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/guardarImagen",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/guardarImagen",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarImagen"
  },

  eliminarImagen: {
    BlogsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    PaqueteriaDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    PaqueteriaProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    TTURDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    TTURProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    CoordinadosDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    CoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    FacturacionEmisionProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    FacturacionEmisionDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    FacturacionEmisionQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarImagen",
    RollBitsDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    RollBitsQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarImagen",
    RollBitsProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    PrimeraPlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    PrimeraPlusProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
    PrimeraPlusQA: "https://4m3qwri685.execute-api.us-east-1.amazonaws.com/qa/contenidos/eliminarImagen",
    SiemprePlusDev: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    ConectaGFA: "https://bx3o787i5f.execute-api.us-east-1.amazonaws.com/dev/contenidos/eliminarImagen",
    ConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/eliminarImagen",
  },



  guardarSucursal: {
    BlogsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    PaqueteriaDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    PaqueteriaProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    TTURDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    TTURProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    CoordinadosDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    CoordinadosProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    FacturacionEmisionDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    FacturacionEmisionProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    RollBitsQA: "",
    RollBitsProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    PrimeraPlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    PrimeraPlusProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    ConectaGFA: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursal",
    ConectaGFAProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursal",
  },

  guardarSucursales: {
    BlogsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    PaqueteriaDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    PaqueteriaProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    TTURDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    TTURProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    CoordinadosDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    CoordinadosProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    FacturacionEmisionDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    FacturacionEmisionQA: "",
    FacturacionEmisionProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    RollBitsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    RollBitsQA: "",
    RollBitsProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    PrimeraPlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    PrimeraPlusProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    ConectaGFA: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/guardarSucursales",
    ConectaGFAProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/guardarSucursales",
  },

  obtenerSucursales: {
    BlogsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    PaqueteriaDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    PaqueteriaProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    TTURDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    TTURProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    CoordinadosDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    CoordinadosProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    FacturacionEmisionDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    FacturacionEmisionQA: "",
    FacturacionEmisionProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    RollBitsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    RollBitsQA: "",
    RollBitsProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    PrimeraPlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    PrimeraPlusProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    ConectaGFA: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/obtenerSucursales",
    ConectaGFAProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/obtenerSucursales",
  },

  obtenerSucursal: {
    BlogsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    PaqueteriaDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    PaqueteriaProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    TTURDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    TTURProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    CoordinadosDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    CoordinadosProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    FacturacionEmisionDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    FacturacionEmisionQA: "",
    FacturacionEmisionProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    RollBitsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    RollBitsQA: "",
    RollBitsProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    PrimeraPlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    PrimeraPlusProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    ConectaGFA: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/ObtenerSucursal",
    ConectaGFAProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/ObtenerSucursal",
  },

  eliminarSucursal: {
    BlogsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    PaqueteriaDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    PaqueteriaProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/eliminarSucursal",
    TTURDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    TTURProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/eliminarSucursal",
    CoordinadosDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    CoordinadosProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/eliminarSucursal",
    FacturacionEmisionDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    FacturacionEmisionQA: "",
    FacturacionEmisionProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/eliminarSucursal",
    RollBitsDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    RollBitsQA: "",
    RollBitsProd: "https://csadofopj0.execute-api.us-east-1.amazonaws.com/prod/sucursales/eliminarSucursal",
    PrimeraPlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    PrimeraPlusProd: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    ConectaGFA: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
    ConectaGFAProd: "https://21i5ts5qu6.execute-api.us-east-1.amazonaws.com/dev/sucursales/eliminarSucursal",
  },



  guardarCiudad: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    FacturacionEmisionQA: "",
    FacturacionEmisionProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    RollBitsQA: "",
    RollBitsProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    PrimeraPlusProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudad",
    ConectaGFAProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudad",
  },

  guardarCiudades: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    RollBitsQA: "",
    RollBitsProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    PrimeraPlusProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/guardarCiudades",
    ConectaGFAProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/guardarCiudades",
  },

  obtenerCiudades: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudades",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudades",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudades",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    RollBitsQA: "",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    PrimeraPlusProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
    ConectaGFAProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudades",
  },

  obtenerCiudadesFront: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudadesFront",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudadesFront",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudadesFront",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    RollBitsQA: "",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    PrimeraPlusProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
    ConectaGFAProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudadesFront",
  },

  obtenerCiudad: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudad",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudad",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/obtenerCiudad",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    RollBitsQA: "",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    PrimeraPlusProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
    ConectaGFAProd: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/obtenerCiudad",
  },

  eliminarCiudad: {
    BlogsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    PaqueteriaDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    PaqueteriaProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/eliminarCiudad",
    TTURDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    TTURProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/eliminarCiudad",
    CoordinadosDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    CoordinadosProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/eliminarCiudad",
    FacturacionEmisionDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    RollBitsQA: "",
    PrimeraPlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    PrimeraPlusProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/eliminarCiudad",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    ConectaGFA: "https://5uimx1pg3g.execute-api.us-east-1.amazonaws.com/dev/ciudades/eliminarCiudad",
    ConectaGFAProd: "https://xqfvjlilzd.execute-api.us-east-1.amazonaws.com/prod/ciudades/eliminarCiudad",
  },


  guardarEstado: {
    BlogsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    PaqueteriaDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    PaqueteriaProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstado",
    TTURDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    TTURProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstado",
    CoordinadosDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    CoordinadosProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstado",
    FacturacionEmisionDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    RollBitsQA: "",
    PrimeraPlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    PrimeraPlusProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstado",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    ConectaGFA: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstado",
    ConectaGFAProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstado",
  },

  guardarEstados: {
    BlogsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    PaqueteriaDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    PaqueteriaProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstados",
    TTURDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    TTURProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstados",
    CoordinadosDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    CoordinadosProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstados",
    FacturacionEmisionDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    RollBitsQA: "",
    PrimeraPlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    PrimeraPlusProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstados",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    ConectaGFA: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/guardarEstados",
    ConectaGFAProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/guardarEstados",
  },

  obtenerEstados: {
    BlogsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    PaqueteriaDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    PaqueteriaProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstados",
    TTURDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    TTURProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstados",
    CoordinadosDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    CoordinadosProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstados",
    FacturacionEmisionDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    RollBitsQA: "",
    PrimeraPlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    PrimeraPlusProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstados",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    ConectaGFA: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstados",
    ConectaGFAProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstados",
  },

  obtenerEstado: {
    BlogsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    PaqueteriaDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    PaqueteriaProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstado",
    TTURDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    TTURProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstado",
    CoordinadosDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    CoordinadosProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstado",
    FacturacionEmisionDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    RollBitsQA: "",
    PrimeraPlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    PrimeraPlusProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstado",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    ConectaGFA: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/obtenerEstado",
    ConectaGFAProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/obtenerEstado",
  },

  eliminarEstado: {
    BlogsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    PaqueteriaDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    PaqueteriaProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/eliminarEstado",
    TTURDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    TTURProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/eliminarEstado",
    CoordinadosDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    CoordinadosProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/eliminarEstado",
    FacturacionEmisionDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    FacturacionEmisionQA: "",
    RollBitsDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    RollBitsQA: "",
    PrimeraPlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    PrimeraPlusProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/eliminarEstado",
    PrimeraPlusQA: "",
    SiemprePlusDev: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    ConectaGFA: "https://j39ga6mz20.execute-api.us-east-1.amazonaws.com/dev/estados/eliminarEstado",
    ConectaGFAProd: "https://19cc332lmk.execute-api.us-east-1.amazonaws.com/prod/estados/eliminarEstado",
  },


  guardarAutobus: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/guardarAutobus",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/guardarAutobus"
  },

  guardarAutobuses: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/guardarAutobuses",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/guardarAutobuses"
  },

  obtenerAutobuses: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/obtenerAutobuses",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/obtenerAutobuses"
  },

  obtenerAutobusesFront: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/obtenerAutobusesFront",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/obtenerAutobusesFront"
  },

  obtenerAutobus: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/obtenerAutobus",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/obtenerAutobus"
  },

  eliminarAutobus: {
    TTURDev: "https://mu2lldh8uc.execute-api.us-east-1.amazonaws.com/dev/autobuses/eliminarAutobus",
    TTURProd: "https://m07shf67mg.execute-api.us-east-1.amazonaws.com/prod/autobuses/eliminarAutobus"
  },

  obtenerFacturasFiltradas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerFacturasFiltradas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerFacturasFiltradas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerFacturasFiltradas"
  },
  obtenerFacturaCompleta: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerFacturaCompleta",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerFacturaCompleta",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerFacturaCompleta"
  },
  obtenerUrlPdfFacturas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerUrlPdfFacturas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerUrlPdfFacturas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerUrlPdfFacturas"
  },
  obtenerUrlXmlFacturas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerUrlXmlFacturas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerUrlXmlFacturas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerUrlXmlFacturas"
  },
  obtenerArchivosFacturas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerArchivosFacturas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerArchivosFacturas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerArchivosFacturas"
  },
  obtenerMultiplesArchivosFacturas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/descargaMasivaFacturas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/descargaMasivaFacturas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/descargaMasivaFacturas"
  },
  cancelarFactura:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/cancelarFactura",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/cancelarFactura",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/cancelarFactura"
  },
  obtenerMotivosCancelacionFacturas:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/MotivosCancelacionFacturas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/MotivosCancelacionFacturas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/MotivosCancelacionFacturas"
  },
  reportePeticion:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/reportePeticion",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/reportePeticion",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/reportePeticion"
  },
  consultarPeticiones:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/consultarPeticiones",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/consultarPeticiones",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/consultarPeticiones"
  },
  obtenerCFDI:{
    FacturacionAdministracionDev:"https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerCFDI",
    FacturacionAdministracionQA:"https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerCFDI",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerCFDI"
  },


  obtenerNominasFiltradas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerNominasFiltradas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerNominasFiltradas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerNominasFiltradas"
  },
  obtenerNominaCompleta: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerNominaCompleta",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerNominaCompleta",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerNominaCompleta"
  },
  obtenerUrlPdfNominas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerUrlPdfNominas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerUrlPdfNominas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerUrlPdfNominas"
  },
  obtenerUrlXmlNominas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerUrlXmlNominas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerUrlXmlNominas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerUrlXmlNominas"
  },
  obtenerArchivosNominas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerArchivosNominas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerArchivosNominas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerArchivosNominas"
  },
  obtenerMultiplesArchivosNominas: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/descargaMasivaNominas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/descargaMasivaNominas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/descargaMasivaNominas"
  },
  cancelarNomina:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/cancelarNomina",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/cancelarNomina",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/cancelarNomina"
  },
  generarListadoNominas:{
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/generarListadoNominas",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/generarListadoNominas",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/generarListadoNominas"
  },
  obtenerTimbrado:{
    FacturacionAdministracionDev:"https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerTimbrado",
    FacturacionAdministracionQA:"https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerTimbrado",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerTimbrado"
  },
  buscarClientes:{
    FacturacionAdministracionDev:"https://vquokgpxol.execute-api.us-east-1.amazonaws.com/dev/cliente/buscarCliente",
    FacturacionAdministracionQA:"https://9royjnbm8i.execute-api.us-east-1.amazonaws.com/qa/cliente/buscarCliente",
    FacturacionAdministracionProd: "https://7u1r7jsrab.execute-api.us-east-1.amazonaws.com/prod/cliente/buscarCliente"
  },
  refacturar: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/refacturar",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/refacturar",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/refacturar"
  },
  consultaRefactura: {
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/consultaRefactura",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/consultaRefactura",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/consultaRefactura"
  },
  obtenerRefacturaListado: {
    RefacturasDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerRefacturaListado",
    RefacturasQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerRefacturaListado",
    RefacturasProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerRefacturaListado"
  },
  claveTipoNa:{
    FacturacionAdministracionDev:"-1",
    FacturacionAdministracionQA:"-1",
    FacturacionAdministracionProd: "-1"
  },
  servicioRegimenFiscal: "4.0",


  obtenerCatalogos: {
    CatalogosDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerCatalogos",
    CatalogosQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerCatalogos",
    CatalogosProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerCatalogos",
    FacturacionAdministracionDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/obtenerCatalogos",
    FacturacionAdministracionQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/obtenerCatalogos",
    FacturacionAdministracionProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/obtenerCatalogos"
  },
  insertarCatalogo: {
    CatalogosDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/insertarCatalogo",
    CatalogosQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/insertarCatalogo",
    CatalogosProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/insertarCatalogo"
  },
  eliminarCatalogo: {
    CatalogosDev: "https://95q0yu11bb.execute-api.us-east-1.amazonaws.com/dev/facturacion-administracion/eliminarCatalogo",
    CatalogosQA: "https://je1a5uz9l2.execute-api.us-east-1.amazonaws.com/qa/facturacion-administracion/eliminarCatalogo",
    CatalogosProd: "https://pecroyo5vk.execute-api.us-east-1.amazonaws.com/prod/facturacion-administracion/eliminarCatalogo"
  },

  recuperacionMasiva:{
    PaqueteriaCartaPorteDev: "https://facturacionemisiondev.primeraplus.com.mx/emision/RecuperacionFacturas.svc/descargaMasivaFacturas",
    EnviosCartaPorteDev: "https://facturacionemisiondev.primeraplus.com.mx/emision/RecuperacionFacturas.svc/descargaMasivaFacturas",
    PaqueteriaCartaPorteQA: "https://facturacionemisiondev.primeraplus.com.mx/emision/RecuperacionFacturas.svc/descargaMasivaFacturas",
    EnviosCartaPorteQA: "https://facturacionemisiondev.primeraplus.com.mx/emision/RecuperacionFacturas.svc/descargaMasivaFacturas",
    PaqueteriaCartaPorteProd: "https://facturacionemisiondev.primeraplus.com.mx/emisionBoletosPROD/RecuperacionFacturas.svc/descargaMasivaFacturas",
    EnviosCartaPorteProd: "https://facturacionemisiondev.primeraplus.com.mx/emisionBoletosPROD/RecuperacionFacturas.svc/descargaMasivaFacturas",
  },

  guardarArchivo: {
    guardarArchivoCoordinadosProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarArchivo",
    guardarArchivoConectaGFAProd: "https://7jxrhjdedb.execute-api.us-east-1.amazonaws.com/prod/contenidos/guardarArchivo",
  },

  guardarAplicacion: {
    DEV: "https://50abm1dkph.execute-api.us-east-1.amazonaws.com/dev/aplicaciones/guardarAplicacion",
    QA: "https://tt67n3rsxb.execute-api.us-east-1.amazonaws.com/qa/aplicaciones/guardarAplicacion",
    PROD: "https://8q8w1al1a7.execute-api.us-east-1.amazonaws.com/prod/aplicaciones/guardarAplicacion"
  },

  eliminarAplicacion: {
    DEV: "https://50abm1dkph.execute-api.us-east-1.amazonaws.com/dev/aplicaciones/eliminarAplicacion",
    QA: "https://tt67n3rsxb.execute-api.us-east-1.amazonaws.com/qa/aplicaciones/eliminarAplicacion",
    PROD: "https://8q8w1al1a7.execute-api.us-east-1.amazonaws.com/prod/aplicaciones/eliminarAplicacion"
  },

  cargarAplicaciones: {
    DEV: "https://50abm1dkph.execute-api.us-east-1.amazonaws.com/dev/aplicaciones/cargarAplicaciones?tipo=&ambiente=",
    QA: "https://tt67n3rsxb.execute-api.us-east-1.amazonaws.com/qa/aplicaciones/cargarAplicaciones?tipo=&ambiente=",
    PROD: "https://8q8w1al1a7.execute-api.us-east-1.amazonaws.com/prod/aplicaciones/cargarAplicaciones?tipo=&ambiente=",
  },

  origenesDestinos: {
    cargarOrigenesDestinos: {
      DEV: "https://hbl5es2v9a.execute-api.us-east-1.amazonaws.com/dev/socios-negocio/origenes-destinos/consultaOrigenesDestinos",
      QA: "https://0eoio1p7aj.execute-api.us-east-1.amazonaws.com/qa/socios-negocio/origenes-destinos/consultaOrigenesDestinos",
      PROD: "https://qnm9ru623b.execute-api.us-east-1.amazonaws.com/prod/socios-negocio/origenes-destinos/consultaOrigenesDestinos"
    },
    cargarOrigenesDestinosActivos: {
      DEV:"https://hbl5es2v9a.execute-api.us-east-1.amazonaws.com/dev/socios-negocio/origenes-destinos/origenesDestinosActivos",
      QA: "https://0eoio1p7aj.execute-api.us-east-1.amazonaws.com/qa/socios-negocio/origenes-destinos/origenesDestinosActivos",
      PROD: "https://qnm9ru623b.execute-api.us-east-1.amazonaws.com/prod/socios-negocio/origenes-destinos/origenesDestinosActivos"
    },
    guardarOrigenesDestinos: {
      DEV: "https://hbl5es2v9a.execute-api.us-east-1.amazonaws.com/dev/socios-negocio/origenes-destinos/guardarOrigenesDestinos",
      QA: "https://0eoio1p7aj.execute-api.us-east-1.amazonaws.com/qa/socios-negocio/origenes-destinos/guardarOrigenesDestinos",
      PROD: "https://qnm9ru623b.execute-api.us-east-1.amazonaws.com/prod/socios-negocio/origenes-destinos/guardarOrigenesDestinos"
    },
    eliminarOrigenesDestinos: {
      DEV: "https://hbl5es2v9a.execute-api.us-east-1.amazonaws.com/dev/socios-negocio/origenes-destinos/eliminarOrigenDestino",
      QA: "https://0eoio1p7aj.execute-api.us-east-1.amazonaws.com/qa/socios-negocio/origenes-destinos/eliminarOrigenDestino",
      PROD: "https://qnm9ru623b.execute-api.us-east-1.amazonaws.com/prod/socios-negocio/origenes-destinos/eliminarOrigenDestino"
    }
  },

  configuracion: {
    guardarConfiguracion: "https://cioopo05ig.execute-api.us-east-1.amazonaws.com/dev/configuraciones/guardarConfiguracion",
    consultarConfiguracion: "https://cioopo05ig.execute-api.us-east-1.amazonaws.com/dev/configuraciones/consultarConfiguracion",
    clave: {
      BlogsDev: "",
      CoordinadosDev: "",
      CoordinadosProd: "",
      FacturacionEmisionDev: "",
      FacturacionEmisionQA: "",
      FacturacionEmisionProd: "",
      RollBitsDev: "",
      RollBitsQA: "",
      PrimeraPlusDev: "0c3f1980-9bb1-11ea-9d01-2fb993d5870c",
      PrimeraPlusProd: "",
      PrimeraPlusQA: "",
      SiemprePlusDev: "",
    }
  },

  oficinas: {
    obtenerOficina: {
      DEV: "https://fmu9tyj2db.execute-api.us-east-1.amazonaws.com/dev/oficinas/obtenerOficina",
      QA: "https://drwkjiirs1.execute-api.us-east-1.amazonaws.com/qa/oficinas/obtenerOficina",
      PROD: "https://oiq2xpvzd9.execute-api.us-east-1.amazonaws.com/prod/oficinas/obtenerOficina"
    },
    obtenerOficinas: {
      DEV: "https://fmu9tyj2db.execute-api.us-east-1.amazonaws.com/dev/oficinas/obtenerOficinas",
      QA: "https://drwkjiirs1.execute-api.us-east-1.amazonaws.com/qa/oficinas/obtenerOficinas",
      PROD: "https://oiq2xpvzd9.execute-api.us-east-1.amazonaws.com/prod/oficinas/obtenerOficinas"
    },
    guardarOficina: {
      DEV: "https://fmu9tyj2db.execute-api.us-east-1.amazonaws.com/dev/oficinas/guardarOficina",
      QA: "https://drwkjiirs1.execute-api.us-east-1.amazonaws.com/qa/oficinas/guardarOficina",
      PROD: "https://oiq2xpvzd9.execute-api.us-east-1.amazonaws.com/prod/oficinas/guardarOficina"
    },
    guardarOficinas: {
      DEV: "https://fmu9tyj2db.execute-api.us-east-1.amazonaws.com/dev/oficinas/guardarOficinas",
      QA: "https://drwkjiirs1.execute-api.us-east-1.amazonaws.com/qa/oficinas/guardarOficinas",
      PROD: "https://oiq2xpvzd9.execute-api.us-east-1.amazonaws.com/prod/oficinas/guardarOficinas"
    },
    eliminarOficina: {
      DEV: "https://fmu9tyj2db.execute-api.us-east-1.amazonaws.com/dev/oficinas/eliminarOficina",
      QA: "https://drwkjiirs1.execute-api.us-east-1.amazonaws.com/qa/oficinas/eliminarOficina",
      PROD: "https://oiq2xpvzd9.execute-api.us-east-1.amazonaws.com/prod/oficinas/eliminarOficina"
    }
  },

  firebase: {
    apiKey: "AIzaSyDtSLIKtyfvVJViC0iFatu5wXE02smAu0s",
    authDomain: "intranet-cli8.firebaseapp.com",
    databaseURL: "https://intranet-cli8.firebaseio.com",
    projectId: "intranet-cli8",
    storageBucket: "intranet-cli8.appspot.com",
    messagingSenderId: "192672596629",
    appId: "1:192672596629:web:3bc7f94a05d05cc246519c",
    measurementId: "G-3T1JJ1C31T"
  },

  envioCorreo: "https://9yf65xtpf9.execute-api.us-east-1.amazonaws.com/dev/socios-negocio/envioCorreo/envioCorreoUsuario"

};
