import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, Subscription } from 'rxjs';

import { GlobalService } from './global.service';
import { Privilegio } from "../entidades/privilegio";
import { PerfilesService } from "../servicios/perfiles.service";
import { UsuariosService } from './usuarios.service';


@Injectable()
export class AuthGuardService implements CanActivate {

    suscripcionDatos: Subscription;
    suscripcionConsulta: Subscription;
    suscripcionSesion: Subscription;

    privilegioAplicaciones: Privilegio = new Privilegio();
    privilegioUsuarios: Privilegio = new Privilegio();
    privilegioPerfiles: Privilegio = new Privilegio();
    privilegioOficinas: Privilegio = new Privilegio();

    privilegioBlogsDev: Privilegio = new Privilegio();
    privilegioFacturacionDev: Privilegio = new Privilegio();
    privilegioFacturacionQA: Privilegio = new Privilegio();
    privilegioFacturacionProd: Privilegio = new Privilegio();
    privilegioRollBitsDev: Privilegio = new Privilegio();
    privilegioRollBitsQA: Privilegio = new Privilegio();
    privilegioRollBitsProd: Privilegio = new Privilegio();
    privilegioCoordinadosDev: Privilegio = new Privilegio();
    privilegioCoordinadosProd: Privilegio = new Privilegio();
    privilegioSiemprePlusDev: Privilegio = new Privilegio();
    privilegioPrimeraPlusDev: Privilegio = new Privilegio();
    privilegioPrimeraPlusProd: Privilegio = new Privilegio();
    privilegioPrimeraPlusQA: Privilegio = new Privilegio();
    privilegioPaqueteriaDev: Privilegio = new Privilegio();
    privilegioPaqueteriaProd: Privilegio = new Privilegio();
    privilegioTTURDev: Privilegio = new Privilegio();
    privilegioTTURProd: Privilegio = new Privilegio();

    privilegioConectaGFA: Privilegio = new Privilegio();
    privilegioConectaGFAProd: Privilegio = new Privilegio();

    privilegioConfiguracionBlogsDev: Privilegio = new Privilegio();
    privilegioConfiguracionFacturacionDev: Privilegio = new Privilegio();
    privilegioConfiguracionFacturacionProd: Privilegio = new Privilegio();
    privilegioConfiguracionRollBitsDev: Privilegio = new Privilegio();
    privilegioConfiguracionRollBitsQA: Privilegio = new Privilegio();
    privilegioConfiguracionRollBitsProd: Privilegio = new Privilegio();
    privilegioConfiguracionCoordinadosDev: Privilegio = new Privilegio();
    privilegioConfiguracionCoordinadosProd: Privilegio = new Privilegio();
    privilegioConfiguracionSiemprePlusDev: Privilegio = new Privilegio();
    privilegioConfiguracionPrimeraPlusDev: Privilegio = new Privilegio();
    privilegioConfiguracionPrimeraPlusProd: Privilegio = new Privilegio();
    privilegioConfiguracionPrimeraPlusQA: Privilegio = new Privilegio();
    privilegioConfiguracionPaqueteriaDev: Privilegio = new Privilegio();
    privilegioConfiguracionPaqueteriaProd: Privilegio = new Privilegio();
    privilegioConfiguracionTTURDev: Privilegio = new Privilegio();
    privilegioConfiguracionTTURProd: Privilegio = new Privilegio();

    privilegioConfiguracionConectaGFA: Privilegio = new Privilegio();
    privilegioConfiguracionConectaGFAProd: Privilegio = new Privilegio();

    /***********************************/
    // operaciones
    /***********************************/

    privilegioOperacionesCoordinadosDev: Privilegio = new Privilegio();
    privilegioOperacionesCoordinadosProd: Privilegio = new Privilegio();
    privilegioOperacionesPrimeraPlusDev: Privilegio = new Privilegio();
    privilegioOperacionesPrimeraPlusProd: Privilegio = new Privilegio();
    privilegioOperacionesPrimeraPlusQA: Privilegio = new Privilegio();

    /***********************************/
    // origenes-destinos
    /***********************************/

    privilegioOrigenesDestinosCoordinadosDev: Privilegio = new Privilegio();
    privilegioOrigenesDestinosCoordinadosProd: Privilegio = new Privilegio();
    privilegioOrigenesDestinosSiemprePlusDev: Privilegio = new Privilegio();
    privilegioOrigenesDestinosPrimeraPlusDev: Privilegio = new Privilegio();
    privilegioOrigenesDestinosPrimeraPlusProd: Privilegio = new Privilegio();
    privilegioOrigenesDestinosPrimeraPlusQA: Privilegio = new Privilegio();

    privilegioOrigenesDestinosConectaGFA: Privilegio = new Privilegio();
    privilegioOrigenesDestinosConectaGFAProd: Privilegio = new Privilegio();

    privilegioSucursalesPaqueteriaDev: Privilegio = new Privilegio();
    privilegioSucursalesPaqueteriaProd: Privilegio = new Privilegio();
    privilegioSucursalesTTURDev: Privilegio = new Privilegio();
    privilegioSucursalesTTURProd: Privilegio = new Privilegio();
    privilegioCiudadesPaqueteriaDev: Privilegio = new Privilegio();
    privilegioCiudadesPaqueteriaProd: Privilegio = new Privilegio();
    privilegioCiudadesTTURDev: Privilegio = new Privilegio();
    privilegioCiudadesTTURProd: Privilegio = new Privilegio();
    privilegioEstadosPaqueteriaDev: Privilegio = new Privilegio();
    privilegioEstadosPaqueteriaProd: Privilegio = new Privilegio();
    privilegioEstadosTTURDev: Privilegio = new Privilegio();
    privilegioEstadosTTURProd: Privilegio = new Privilegio();
    privilegioAutobusesTTURDev: Privilegio = new Privilegio();
    privilegioAutobusesTTURProd: Privilegio = new Privilegio();


    /***********************************/
    // facturas
    /***********************************/

    privilegioFacturasFacturacionAdministracionDev: Privilegio = new Privilegio();
    privilegioFacturasFacturacionAdministracionQA: Privilegio = new Privilegio();
    privilegioFacturasFacturacionAdministracionProd: Privilegio = new Privilegio();

    /***********************************/
    // Refacturas
    /***********************************/

    privilegioRefacturasDev: Privilegio = new Privilegio();

    /***********************************/
    // nominas
    /***********************************/
    
    privilegioNominasFacturacionAdministracionDev: Privilegio = new Privilegio();
    privilegioNominasFacturacionAdministracionQA: Privilegio = new Privilegio();
    privilegioNominasFacturacionAdministracionProd: Privilegio = new Privilegio();

    /***********************************/
    // reportes
    /***********************************/
    
    privilegioReportesFacturacionAdministracionDev: Privilegio = new Privilegio();
    privilegioReportesFacturacionAdministracionQA: Privilegio = new Privilegio();
    privilegioReportesFacturacionAdministracionProd: Privilegio = new Privilegio();
    
    /***********************************/
    // catalogos
    /***********************************/

    privilegiocatalogosDev: Privilegio = new Privilegio();
    privilegiocatalogosQA: Privilegio = new Privilegio();
    privilegiocatalogosProd: Privilegio = new Privilegio();

    /***********************************/
    // recuperaciones
    /***********************************/

    privilegioRecuperacionPaqueteriaCartaPorteDev: Privilegio = new Privilegio();
    privilegioRecuperacionEnviosCartaPorteDev: Privilegio = new Privilegio();
    privilegioRecuperacionPaqueteriaCartaPorteProd: Privilegio = new Privilegio();
    privilegioRecuperacionEnviosCartaPorteProd: Privilegio = new Privilegio();
    privilegioRecuperacionPaqueteriaCartaPorteQA: Privilegio = new Privilegio();
    privilegioRecuperacionEnviosCartaPorteQA: Privilegio = new Privilegio();

 

    constructor(
        private navegacion: Router,
        private fireAuth: AngularFireAuth,
        private servicioGlobal: GlobalService,
        private servicioPerfiles: PerfilesService,
        private servicioUsuario: UsuariosService
    ) {

        this.fireAuth.auth.onAuthStateChanged(
            async respuesta => {
                if (respuesta == null) {
                    await this.servicioGlobal.cambiarEstadoSesion(false);
                } else {
                    await this.servicioGlobal.cambiarEstadoSesion(true);
                }
            }
        );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

        let modulo: string = '';
        let hijo: string = '';
        let contenidoModulo: string = '';
        let contenidoHijo: string = '';

        //console.log("ruta:", route);
        //console.log("[RUTA_PADRE]:", JSON.stringify(route.parent.url, null, 4));
        //console.log("[RUTA_HIJO]:", JSON.stringify(route.url, null, 4));
        /* route.params.identificador; */

        switch (route.parent.url.length) {
            case 0: {
                switch (route.url.length) {

                    case 1: {
                        modulo = route.url[0].path;
                        contenidoModulo = route.params.identificador;
                        break;
                    }

                    case 2: {
                        modulo = route.url[0].path;
                        contenidoModulo = route.url[1].path;
                        break;
                    }

                }
                break
            }
            case 1: {
                switch (route.url.length) {

                    case 0: {
                        modulo = route.parent.url[0].path;
                        contenidoModulo = route.params.identificador;
                        break;
                    }

                    case 1: {

                        modulo = route.parent.url[0].path;
                        hijo = route.url[0].path;

                        break;
                    }

                    case 2: {
                        modulo = route.parent.url[0].path;
                        hijo = route.url[0].path;
                        contenidoHijo = route.url[0].path;
                        break;
                    }

                }
                break
            }
            case 2: {
                switch (route.url.length) {

                    case 0: {
                        modulo = route.parent.url[0].path;
                        contenidoModulo = route.parent.url[1].path;
                        break;
                    }

                    case 1: {
                        modulo = route.parent.url[0].path;
                        contenidoModulo = route.parent.url[1].path;
                        hijo = route.url[0].path;
                        break;
                    }

                    case 2: {
                        modulo = route.parent.url[0].path;
                        contenidoModulo = route.parent.url[1].path;
                        hijo = route.url[0].path;
                        contenidoHijo = route.url[1].path;
                        break;
                    }

                }
                break
            }
            default: {
                console.log ("entro al default de las rutas con "+route.parent.url.length);
            }
        }

        console.log("modulo auth guard: ", modulo);
        console.log("contenido modulo auth guard: ", contenidoModulo);
        console.log("hijo auth guard: ", hijo);


        if (hijo == 'inicio-sesion' || hijo == 'registro' || hijo == 'reiniciar-password') {

            this.servicioGlobal.cambiarEstadoLoader(true);

            return new Observable<boolean>(observador => {

                this.fireAuth.auth.onAuthStateChanged(
                    async respuesta => { 

                        console.log("respuesta: ", respuesta);

                        if (respuesta == null) {
                            await this.servicioUsuario.eliminarUsuarioLocal();
                            observador.next(true);
                        } else {
                            this.navegacion.navigate(['/inicio']);
                            observador.next(false);
                        }

                        this.servicioGlobal.cambiarEstadoLoader(false);
                        observador.complete();

                    },
                    error => {
                        console.log(error);
                        this.servicioGlobal.cambiarEstadoLoader(false);
                    });
            });

        } else if ( modulo == 'catalogos'|| modulo == 'recuperacion' || modulo == 'reportes' || modulo == 'nominas' ||modulo == 'facturas' || modulo == 'autobuses' || modulo == 'estados' || modulo == 'ciudades' || modulo == 'sucursales' || modulo == 'configuracion' || modulo == 'origenes-destinos' || modulo == 'oficinas' || modulo == 'contenido' || modulo == 'usuarios' || modulo == 'perfiles' || modulo == 'aplicaciones' || modulo == 'aplicaciones-gfa' || modulo == 'refacturas') {

            this.servicioGlobal.cambiarEstadoLoader(true);
            return new Observable<boolean>(observador => {
                this.fireAuth.auth.onAuthStateChanged(async sesion => {
                    if (sesion != null) {
                        this.suscripcionDatos = await this.servicioGlobal.informacionActiva.subscribe(
                            async valor => {
                                if (valor) {
                                    this.suscripcionConsulta = await this.servicioPerfiles.cargarPerfilLocal().subscribe(
                                        suscribe => {
                                            console.log("auth guard perfil", suscribe)
                                            suscribe.privilegios.forEach(privilegio => {

                                                switch (privilegio.modulo) {

                                                    case 'aplicaciones': {
                                                        this.privilegioAplicaciones = privilegio;
                                                        break;
                                                    };
                                                    case 'usuarios': {
                                                        this.privilegioUsuarios = privilegio;
                                                        break;
                                                    };
                                                    case 'perfiles': {
                                                        this.privilegioPerfiles = privilegio;
                                                        break;
                                                    };
                                                    case 'oficinas': {
                                                        this.privilegioOficinas = privilegio;
                                                        break;
                                                    };

                                                    /*************/
                                                    /* Contenido */
                                                    /************/

                                                    case 'edicionContenidoCoordinadosDev': {
                                                        this.privilegioCoordinadosDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoCoordinadosProd': {
                                                        this.privilegioCoordinadosProd = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoSiemprePlusDev': {
                                                        this.privilegioSiemprePlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoPrimeraPlusDev': {
                                                        this.privilegioPrimeraPlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoPrimeraPlusProd': {
                                                        this.privilegioPrimeraPlusProd = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoPrimeraPlusQA': {
                                                        this.privilegioPrimeraPlusQA = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoBlogsDev': {
                                                        this.privilegioBlogsDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoFacturacionEmisionDev': {
                                                        this.privilegioFacturacionDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoFacturacionEmisionQA': {
                                                        this.privilegioFacturacionQA = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoFacturacionEmisionProd': {
                                                        this.privilegioFacturacionProd = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoRollBitsDev': {
                                                        this.privilegioRollBitsDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoRollBitsQA': {
                                                        this.privilegioRollBitsQA = privilegio;
                                                        break;
                                                    };

                                                    case 'edicionContenidoRollBitsProd': {
                                                        this.privilegioRollBitsProd = privilegio;
                                                        break;
                                                    };

                                                    case 'edicionContenidoConectaGFA': {
                                                        this.privilegioConectaGFA = privilegio;
                                                        break;
                                                    };

                                                    case 'edicionContenidoConectaGFAProd': {
                                                        this.privilegioConectaGFAProd = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoPaqueteriaDev': {
                                                        this.privilegioPaqueteriaDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoPaqueteriaProd': {
                                                        this.privilegioPaqueteriaProd = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoTTURDev': {
                                                        this.privilegioTTURDev = privilegio;
                                                        break;
                                                    };
                                                    case 'edicionContenidoTTURProd': {
                                                        this.privilegioTTURProd = privilegio;
                                                        break;
                                                    };

                                                    /*****************/
                                                    /* Configuración */
                                                    /****************/

                                                    case 'configuracionCoordinadosDev': {
                                                        this.privilegioConfiguracionCoordinadosDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionCoordinadosProd': {
                                                        this.privilegioConfiguracionCoordinadosProd = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionSiemprePlusDev': {
                                                        this.privilegioConfiguracionSiemprePlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionPrimeraPlusDev': {
                                                        this.privilegioConfiguracionPrimeraPlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionPrimeraPlusProd': {
                                                        this.privilegioConfiguracionPrimeraPlusProd = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionPrimeraPlusQA': {
                                                        this.privilegioConfiguracionPrimeraPlusQA = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionBlogsDev': {
                                                        this.privilegioConfiguracionBlogsDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionFacturacionEmisionDev': {
                                                        this.privilegioConfiguracionFacturacionDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionFacturacionEmisionProd': {
                                                        this.privilegioConfiguracionFacturacionProd = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionRollBitsDev': {
                                                        this.privilegioConfiguracionRollBitsDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionRollBitsQA': {
                                                        this.privilegioConfiguracionRollBitsQA = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionRollBitsProd': {
                                                        this.privilegioConfiguracionRollBitsProd = privilegio;
                                                        break;
                                                    };

                                                    case 'configuracionConectaGFA': {
                                                        this.privilegioConfiguracionConectaGFA = privilegio;
                                                        break;
                                                    };

                                                    case 'configuracionConectaGFAProd': {
                                                        this.privilegioConfiguracionConectaGFAProd = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionPaqueteriaDev': {
                                                        this.privilegioConfiguracionPaqueteriaDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionPaqueteriaProd': {
                                                        this.privilegioConfiguracionPaqueteriaProd = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionTTURDev': {
                                                        this.privilegioConfiguracionTTURDev = privilegio;
                                                        break;
                                                    };
                                                    case 'configuracionTTURProd': {
                                                        this.privilegioConfiguracionTTURProd = privilegio;
                                                        break;
                                                    };

                                                    /*****************/
                                                    /* Operaciones */
                                                    /****************/

                                                    case 'operacionesCoordinadosDev': {
                                                        this.privilegioOperacionesCoordinadosDev = privilegio;
                                                        break;
                                                    };
                                                    case 'operacionesCoordinadosProd': {
                                                        this.privilegioOperacionesCoordinadosProd = privilegio;
                                                        break;
                                                    };
                                                    case 'operacionesPrimeraPlusDev': {
                                                        this.privilegioOperacionesPrimeraPlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'operacionesPrimeraPlusProd': {
                                                        this.privilegioOperacionesPrimeraPlusProd = privilegio;
                                                        break;
                                                    };
                                                    case 'operacionesPrimeraPlusQA': {
                                                        this.privilegioOperacionesPrimeraPlusQA = privilegio;
                                                        break;
                                                    };

                                                    /*********************/
                                                    /* Origenes Destinos */
                                                    /*********************/

                                                    case 'origenesDestinosCoordinadosDev': {
                                                        this.privilegioOrigenesDestinosCoordinadosDev = privilegio;
                                                        break;
                                                    };
                                                    case 'origenesDestinosCoordinadosProd': {
                                                        this.privilegioOrigenesDestinosCoordinadosProd = privilegio;
                                                        break;
                                                    };
                                                    case 'origenesDestinosSiemprePlusDev': {
                                                        this.privilegioOrigenesDestinosSiemprePlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'origenesDestinosPrimeraPlusDev': {
                                                        this.privilegioOrigenesDestinosPrimeraPlusDev = privilegio;
                                                        break;
                                                    };
                                                    case 'origenesDestinosPrimeraPlusProd': {
                                                        this.privilegioOrigenesDestinosPrimeraPlusProd = privilegio;
                                                        break;
                                                    };

                                                    case 'origenesDestinosPrimeraPlusQA': {
                                                        this.privilegioOrigenesDestinosPrimeraPlusQA = privilegio;
                                                        break;
                                                    };

                                                    case 'origenesDestinosConectaGFA': {
                                                        this.privilegioOrigenesDestinosConectaGFA = privilegio;
                                                        break;
                                                    };

                                                    case 'origenesDestinosConectaGFAProd': {
                                                        this.privilegioOrigenesDestinosConectaGFAProd = privilegio;
                                                        break;
                                                    };

                                                    /**************/
                                                    /* Sucursales */
                                                    /**************/

                                                    case 'sucursalesPaqueteriaDev': {
                                                        this.privilegioSucursalesPaqueteriaDev = privilegio;
                                                        break;
                                                    };
                                                    case 'sucursalesPaqueteriaProd': {
                                                        this.privilegioSucursalesPaqueteriaProd = privilegio;
                                                        break;
                                                    };
                                                    case 'sucursalesTTURDev': {
                                                        this.privilegioSucursalesTTURDev = privilegio;
                                                        break;
                                                    };
                                                    case 'sucursalesTTURProd': {
                                                        this.privilegioSucursalesTTURProd = privilegio;
                                                        break;
                                                    };

                                                    /************/
                                                    /* Ciudades */
                                                    /************/

                                                    case 'ciudadesPaqueteriaDev': {
                                                        this.privilegioCiudadesPaqueteriaDev = privilegio;
                                                        break;
                                                    };
                                                    case 'ciudadesPaqueteriaProd': {
                                                        this.privilegioCiudadesPaqueteriaProd = privilegio;
                                                        break;
                                                    };
                                                    case 'ciudadesTTURDev': {
                                                        this.privilegioCiudadesTTURDev = privilegio;
                                                        break;
                                                    };
                                                    case 'ciudadesTTURProd': {
                                                        this.privilegioCiudadesTTURProd = privilegio;
                                                        break;
                                                    };

                                                    /************/
                                                    /* Estados  */
                                                    /************/

                                                    case 'estadosPaqueteriaDev': {
                                                        this.privilegioEstadosPaqueteriaDev = privilegio;
                                                        break;
                                                    };
                                                    case 'estadosPaqueteriaProd': {
                                                        this.privilegioEstadosPaqueteriaProd = privilegio;
                                                        break;
                                                    };
                                                    case 'estadosTTURDev': {
                                                        this.privilegioEstadosTTURDev = privilegio;
                                                        break;
                                                    };
                                                    case 'estadosTTURProd': {
                                                        this.privilegioEstadosTTURProd = privilegio;
                                                        break;
                                                    };

                                                    /*************/                                                   /************/
                                                    /* Autobuses */
                                                    /*************/

                                                    case 'autobusesTTURDev': {
                                                        this.privilegioAutobusesTTURDev = privilegio;
                                                        break;
                                                    }
                                                    case 'autobusesTTURProd': {
                                                        this.privilegioAutobusesTTURProd = privilegio;
                                                        break;
                                                    }

                                                    /*************/                                                   /************/
                                                    /* Facturas  */
                                                    /*************/

                                                    case 'facturasFacturacionAdministracionDev': {
                                                        this.privilegioFacturasFacturacionAdministracionDev = privilegio;
                                                        break;
                                                    }

                                                    case 'facturasFacturacionAdministracionQA': {
                                                        this.privilegioFacturasFacturacionAdministracionQA = privilegio;
                                                        break;
                                                    }

                                                    case 'facturasFacturacionAdministracionProd': {
                                                        this.privilegioFacturasFacturacionAdministracionProd = privilegio;
                                                        break;
                                                    }

                                                    /**************/                                                   /************/
                                                    /* Refacturas */
                                                    /**************/

                                                    case 'refacturasDev': {
                                                        this.privilegioRefacturasDev = privilegio;
                                                        break;
                                                    }

                                                    /*************/                                                   /************/
                                                    /* Nominas   */
                                                    /*************/


                                                    case 'nominasFacturacionAdministracionDev': {
                                                        this.privilegioNominasFacturacionAdministracionDev = privilegio;
                                                        break;
                                                    }

                                                    case 'nominasFacturacionAdministracionQA': {
                                                        this.privilegioNominasFacturacionAdministracionQA = privilegio;
                                                        break;
                                                    }

                                                    case 'nominasFacturacionAdministracionProd': {
                                                        this.privilegioNominasFacturacionAdministracionProd = privilegio;
                                                        break;
                                                    }

                                                    /*************/                                                   /************/
                                                    /* Reportes  */
                                                    /*************/


                                                    case 'reportesFacturacionAdministracionDev': {
                                                        this.privilegioReportesFacturacionAdministracionDev = privilegio;
                                                        break;
                                                    }

                                                    case 'reportesFacturacionAdministracionQA': {
                                                        this.privilegioReportesFacturacionAdministracionQA = privilegio;
                                                        break;
                                                    }

                                                    case 'reportesFacturacionAdministracionProd': {
                                                        this.privilegioReportesFacturacionAdministracionProd = privilegio;
                                                        break;
                                                    }

                                                    /****************************/                                                   /************/
                                                    /* Recuperacion Paqueteria  */
                                                    /****************************/

                                                    case 'recuperacionPaqueteriaCartaPorteDev': {
                                                        this.privilegioRecuperacionPaqueteriaCartaPorteDev = privilegio;
                                                        break;
                                                    }

                                                    case 'recuperacionPaqueteriaCartaPorteProd': {
                                                        this.privilegioRecuperacionPaqueteriaCartaPorteProd = privilegio;
                                                        break;
                                                    }

                                                    case 'recuperacionPaqueteriaCartaPorteQA': {
                                                        this.privilegioRecuperacionPaqueteriaCartaPorteQA = privilegio;
                                                        break;
                                                    }

                                                    /****************************/                                                   /************/
                                                    /* Recuperacion Envíos      */
                                                    /****************************/

                                                    case 'recuperacionEnviosCartaPorteDev': {
                                                        this.privilegioRecuperacionEnviosCartaPorteDev = privilegio;
                                                        break;
                                                    }

                                                    case 'recuperacionEnviosCartaPorteProd': {
                                                        this.privilegioRecuperacionEnviosCartaPorteProd = privilegio;
                                                        break;
                                                    }

                                                    case 'recuperacionEnviosCartaPorteQA': {
                                                        this.privilegioRecuperacionEnviosCartaPorteQA = privilegio;
                                                        break;
                                                    }

                                                     /****************************/                                                   /************/
                                                    /* Recuperacion Envíos      */
                                                    /****************************/

                                                    case 'catalogosDev': {
                                                        this.privilegiocatalogosDev = privilegio;
                                                        break;
                                                    }

                                                    case 'catalogosQA': {
                                                        this.privilegiocatalogosQA = privilegio;
                                                        break;
                                                    }

                                                    case 'catalogosProd': {
                                                        this.privilegiocatalogosProd = privilegio;
                                                        break;
                                                    }

                                                    default: {
                                                        console.log("[Privilegio Modulo]", privilegio.modulo);
                                                        console.log("entró en el default de los privilegios");
                                                        break;
                                                    };
                                                }

                                            },
                                                error => {
                                                    console.log(error);
                                                    observador.next(false);
                                                    this.servicioGlobal.cambiarEstadoLoader(false);
                                                    observador.complete();
                                                });


                                        },
                                        error => {
                                            console.log(error);
                                            observador.next(false);
                                            this.servicioGlobal.cambiarEstadoLoader(false);
                                            observador.complete();
                                        });


                                    switch (modulo) {

                                        case 'contenido': {
                                            console.log("[<contenido modulo>]", contenidoModulo);
                                            console.log("[<privilegio coordinados>]", this.privilegioCoordinadosDev);
                                            switch (contenidoModulo) {

                                                case 'CoordinadosDev': {
                                                    if (this.privilegioCoordinadosDev.lista) {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;

                                                    } else {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'CoordinadosProd': {
                                                    if (this.privilegioCoordinadosDev.lista) {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;

                                                    } else {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'FacturacionEmisionDev': {
                                                    if (!this.privilegioFacturacionDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'FacturacionEmisionQA': {
                                                    if (!this.privilegioFacturacionQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'FacturacionEmisionProd': {
                                                    if (!this.privilegioFacturacionProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsDev': {
                                                    if (!this.privilegioRollBitsDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsQA': {
                                                    if (!this.privilegioRollBitsQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsProd': {
                                                    if (!this.privilegioRollBitsProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioPrimeraPlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioPrimeraPlusProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioPrimeraPlusQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'SiemprePlusDev': {
                                                    if (!this.privilegioSiemprePlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'BlogsDev': {
                                                    if (!this.privilegioBlogsDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA': {
                                                    if (!this.privilegioConectaGFA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFAProd': {
                                                    if (!this.privilegioConectaGFAProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioPaqueteriaDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioPaqueteriaProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {

                                                    if (!this.privilegioTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'TTURProd': {

                                                    if (!this.privilegioTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de los modulos de contenido");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'configuracion': {

                                            switch (contenidoModulo) {

                                                case 'ServiciosCoordinadosDev': {
                                                    if (!this.privilegioConfiguracionCoordinadosDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'ServiciosCoordinadosProd': {
                                                    if (!this.privilegioConfiguracionCoordinadosProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'FacturacionEmisionDev': {
                                                    if (!this.privilegioConfiguracionFacturacionDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }
                                                case 'FacturacionEmisionProd': {
                                                    if (!this.privilegioConfiguracionFacturacionProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsDev': {
                                                    if (!this.privilegioConfiguracionRollBitsDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsQA': {
                                                    if (!this.privilegioConfiguracionRollBitsQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'RollBitsProd': {
                                                    if (!this.privilegioConfiguracionRollBitsProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'SiemprePlusDev': {
                                                    if (!this.privilegioConfiguracionSiemprePlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'BlogsDev': {
                                                    if (!this.privilegioConfiguracionBlogsDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA': {
                                                    if (!this.privilegioConfiguracionConectaGFA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioConfiguracionPaqueteriaDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioConfiguracionPaqueteriaProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioConfiguracionTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioConfiguracionTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'operaciones': {

                                            switch (contenidoModulo) {

                                                case 'ServiciosCoordinadosDev': {
                                                    if (!this.privilegioOperacionesCoordinadosDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'ServiciosCoordinadosProd': {
                                                    if (!this.privilegioOperacionesCoordinadosProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioOperacionesPrimeraPlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioOperacionesPrimeraPlusProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioOperacionesPrimeraPlusQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de las operaciones de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'sucursales': {

                                            switch (contenidoModulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioSucursalesPaqueteriaDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioSucursalesPaqueteriaProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioSucursalesTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioSucursalesTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'ciudades': {

                                            switch (contenidoModulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioCiudadesPaqueteriaDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioCiudadesPaqueteriaProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioCiudadesTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioCiudadesTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'estados': {

                                            switch (contenidoModulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioEstadosPaqueteriaDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioEstadosPaqueteriaProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioEstadosTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioEstadosTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'autobuses': {

                                            switch (contenidoModulo) {

                                                case 'TTURDev': {
                                                    if (!this.privilegioAutobusesTTURDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'TTURProd': {
                                                    if (!this.privilegioAutobusesTTURProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'facturas': {

                                            switch (contenidoModulo) {

                                                case 'FacturacionAdministracionDev': {
                                                    if (!this.privilegioFacturasFacturacionAdministracionDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionQA': {
                                                    if (!this.privilegioFacturasFacturacionAdministracionQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionProd': {
                                                    if (!this.privilegioFacturasFacturacionAdministracionProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'refacturas': {

                                            switch (contenidoModulo) {

                                                case 'RefacturasDev': {
                                                    if (!this.privilegioRefacturasDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'nominas': {

                                            switch (contenidoModulo) {

                                                case 'FacturacionAdministracionDev': {
                                                    if (!this.privilegioNominasFacturacionAdministracionDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionQA': {
                                                    if (!this.privilegioNominasFacturacionAdministracionQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionProd': {
                                                    if (!this.privilegioNominasFacturacionAdministracionProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'reportes': {

                                            switch (contenidoModulo) {

                                                case 'FacturacionAdministracionDev': {
                                                    if (!this.privilegioReportesFacturacionAdministracionDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionQA': {
                                                    if (!this.privilegioReportesFacturacionAdministracionQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionAdministracionProd': {
                                                    if (!this.privilegioReportesFacturacionAdministracionProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'catalogos': {

                                            switch (contenidoModulo) {

                                                case 'catalogosDev': {
                                                    if (!this.privilegiocatalogosDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'catalogosQA': {
                                                    if (!this.privilegiocatalogosQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'catalogosProd': {
                                                    if (!this.privilegiocatalogosProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de la configuracion de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'recuperacion': {

                                            switch (contenidoModulo) {

                                                case 'recuperacionPaqueteriaCartaPorteDev': {
                                                    if (!this.privilegioRecuperacionPaqueteriaCartaPorteDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'recuperacionPaqueteriaCartaPorteProd': {
                                                    if (!this.privilegioRecuperacionPaqueteriaCartaPorteProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'recuperacionPaqueteriaCartaPorteQA': {
                                                    if (!this.privilegioRecuperacionPaqueteriaCartaPorteQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'recuperacionEnviosCartaPorteDev': {
                                                    if (!this.privilegioRecuperacionEnviosCartaPorteDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'recuperacionEnviosCartaPorteProd': {
                                                    if (!this.privilegioRecuperacionEnviosCartaPorteProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'recuperacionEnviosCartaPorteQA': {
                                                    if (!this.privilegioRecuperacionEnviosCartaPorteQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                default: {
                                                    console.log("entro al default de las operaciones de modulos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'origenes-destinos': {

                                            switch (contenidoModulo) {

                                                case 'ServiciosCoordinados-DEV': {
                                                    if (!this.privilegioOrigenesDestinosCoordinadosDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'ServiciosCoordinados-PROD': {
                                                    if (!this.privilegioOrigenesDestinosCoordinadosDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }

                                                }

                                                case 'PrimeraPlus-DEV': {
                                                    if (!this.privilegioOrigenesDestinosPrimeraPlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlus-PROD': {
                                                    if (!this.privilegioOrigenesDestinosPrimeraPlusProd.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlus-QA': {
                                                    if (!this.privilegioOrigenesDestinosPrimeraPlusQA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'SiemprePlus-DEV': {
                                                    if (!this.privilegioOrigenesDestinosSiemprePlusDev.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA-DEV': {
                                                    if (!this.privilegioOrigenesDestinosConectaGFA.lista) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                default: {
                                                    console.log("entro al default de los modulos de origenes y destinos");
                                                    break;
                                                }

                                            }

                                            break;
                                        };

                                        case 'aplicaciones': {
                                            if (!this.privilegioAplicaciones.lista) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        };

                                        case 'oficinas': {
                                            if (!this.privilegioOficinas.lista) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        };

                                        case 'usuarios': {
                                            if (!this.privilegioUsuarios.lista) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        };

                                        case 'perfiles': {
                                            if (!this.privilegioPerfiles.lista) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }

                                        };

                                        default: {
                                            console.log('Entro al default');
                                            observador.next(false);
                                            this.servicioGlobal.cambiarEstadoLoader(false);
                                            observador.complete();
                                            break
                                        };

                                    }

                                    switch (hijo) {

                                        case 'nueva-aplicacion': {
                                            if (!this.privilegioAplicaciones.nuevo) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'nuevo-reporte': {
                                            if (!this.privilegioReportesFacturacionAdministracionDev.descargaIndividual || !this.privilegioReportesFacturacionAdministracionProd.descargaIndividual) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'nuevo-contenido': {
                                            switch (modulo) {
                                                case 'ServiciosCoordinadosDev': {
                                                    if (!this.privilegioCoordinadosDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'ServiciosCoordinadosProd': {
                                                    if (!this.privilegioCoordinadosProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionEmisionDev': {
                                                    if (!this.privilegioFacturacionDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionEmisionQA': {
                                                    if (!this.privilegioFacturacionQA.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'FacturacionEmisionProd': {
                                                    if (!this.privilegioFacturacionProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'RollBitsDev': {
                                                    if (!this.privilegioRollBitsDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'RollBitsQA': {
                                                    if (!this.privilegioRollBitsQA.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'RollBitsProd': {
                                                    if (!this.privilegioRollBitsProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioPrimeraPlusDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioPrimeraPlusProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioPrimeraPlusQA.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'BlogsDev': {
                                                    if (!this.privilegioBlogsDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA': {
                                                    if (!this.privilegioConectaGFA.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFAProd': {
                                                    if (!this.privilegioConectaGFAProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioPaqueteriaDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioPaqueteriaProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                            }
                                            break;
                                        }

                                        case 'nueva-sucursal': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioSucursalesPaqueteriaDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioSucursalesPaqueteriaProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioSucursalesTTURDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                case 'TTURProd': {
                                                    if (!this.privilegioSucursalesTTURProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }


                                            }
                                            break;
                                        }

                                        case 'nueva-ciudad': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioCiudadesPaqueteriaDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioCiudadesPaqueteriaProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioCiudadesTTURDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                
                                                case 'TTURProd': {
                                                    if (!this.privilegioCiudadesTTURProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }


                                            }
                                            break;
                                        }

                                        case 'nuevo-estado': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioEstadosPaqueteriaDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioEstadosPaqueteriaProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioEstadosTTURDev.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioEstadosTTURProd.nuevo) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }


                                            }
                                            break;
                                        }

                                        case 'nueva-oficina': {
                                            if (!this.privilegioOficinas.nuevo) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'nuevo-perfil': {
                                            if (!this.privilegioPerfiles.nuevo) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'nuevo-usuario': {
                                            if (!this.privilegioUsuarios.nuevo) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'editar-aplicacion': {
                                            if (!this.privilegioAplicaciones.editar) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'editar-contenido': {

                                            switch (modulo) {

                                                case 'ServiciosCoordinadosDev': {
                                                    if (!this.privilegioCoordinadosDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ServiciosCoordinadosProd': {
                                                    if (!this.privilegioCoordinadosProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'FacturacionEmisionDev': {
                                                    if (!this.privilegioFacturacionDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'FacturacionEmisionQA': {
                                                    if (!this.privilegioFacturacionQA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'FacturacionEmisionProd': {
                                                    if (!this.privilegioFacturacionProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'SiemprePlusDev': {
                                                    if (!this.privilegioSiemprePlusDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioPrimeraPlusDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioPrimeraPlusProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioPrimeraPlusQA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsDev': {
                                                    if (!this.privilegioRollBitsDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsQA': {
                                                    if (!this.privilegioRollBitsQA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsProd': {
                                                    if (!this.privilegioRollBitsProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'BlogsDev': {
                                                    if (!this.privilegioBlogsDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA': {
                                                    if (!this.privilegioConectaGFA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFAProd': {
                                                    if (!this.privilegioConectaGFAProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                            }
                                            break;
                                        }

                                        case 'editar-sucursal': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioSucursalesPaqueteriaDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioSucursalesPaqueteriaProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioSucursalesTTURDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioSucursalesTTURProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                                

                                            }
                                            break;
                                        }

                                        case 'editar-ciudad': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioCiudadesPaqueteriaDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioCiudadesPaqueteriaProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioCiudadesTTURDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioCiudadesTTURProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }


                                            }
                                            break;
                                        }

                                        case 'editar-estado': {
                                            switch (modulo) {

                                                case 'PaqueteriaDev': {
                                                    if (!this.privilegioEstadosPaqueteriaDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PaqueteriaProd': {
                                                    if (!this.privilegioEstadosPaqueteriaProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURDev': {
                                                    if (!this.privilegioEstadosTTURDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'TTURProd': {
                                                    if (!this.privilegioEstadosTTURProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }


                                            }
                                            break;
                                        }

                                        case 'editar-configuracion': {

                                            switch (modulo) {

                                                case 'ServiciosCoordinadosDev': {
                                                    if (!this.privilegioConfiguracionCoordinadosDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ServiciosCoordinadosProd': {
                                                    if (!this.privilegioConfiguracionCoordinadosProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'FacturacionEmisionDev': {
                                                    if (!this.privilegioConfiguracionFacturacionDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'FacturacionEmisionProd': {
                                                    if (!this.privilegioConfiguracionFacturacionProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'SiemprePlusDev': {
                                                    if (!this.privilegioConfiguracionSiemprePlusDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusDev': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusProd': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'PrimeraPlusQA': {
                                                    if (!this.privilegioConfiguracionPrimeraPlusQA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsDev': {
                                                    if (!this.privilegioConfiguracionRollBitsDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsQA': {
                                                    if (!this.privilegioConfiguracionRollBitsQA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'RollBitsProd': {
                                                    if (!this.privilegioConfiguracionRollBitsProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'BlogsDev': {
                                                    if (!this.privilegioConfiguracionBlogsDev.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFA': {
                                                    if (!this.privilegioConfiguracionConectaGFA.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }

                                                case 'ConectaGFAProd': {
                                                    if (!this.privilegioConfiguracionConectaGFAProd.editar) {
                                                        observador.next(false);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    } else {
                                                        observador.next(true);
                                                        this.servicioGlobal.cambiarEstadoLoader(false);
                                                        observador.complete();
                                                        break;
                                                    }
                                                }
                                            }
                                            break;
                                        }

                                        case 'editar-oficina': {
                                            if (!this.privilegioOficinas.editar) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'editar-perfil': {
                                            if (!this.privilegioPerfiles.editar) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'editar-usuario': {
                                            if (!this.privilegioUsuarios.editar) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        case 'alta-masiva': {
                                            if (!this.privilegioUsuarios.descargaMasiva) {
                                                observador.next(false);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            } else {
                                                observador.next(true);
                                                this.servicioGlobal.cambiarEstadoLoader(false);
                                                observador.complete();
                                                break;
                                            }
                                        }

                                        default: {
                                            console.log("[<Modulo>]", modulo);
                                            console.log('sin hijos');
                                            observador.next(true);
                                            this.servicioGlobal.cambiarEstadoLoader(false);
                                            observador.complete();
                                            break
                                        }
                                    }

                                } else {
                                    this.navegacion.navigate(['/usuarios/inicio-sesion']);
                                    observador.next(false);
                                    this.servicioGlobal.cambiarEstadoLoader(false);
                                    observador.complete();
                                }

                            },
                            error => {
                                console.log(error);
                                observador.next(false);
                                this.servicioGlobal.cambiarEstadoLoader(false);
                                observador.complete();
                            },
                        )
                    } else {
                        this.navegacion.navigate(['/usuarios/inicio-sesion']);
                        observador.next(false);
                        this.servicioGlobal.cambiarEstadoLoader(false);
                        observador.complete();
                    }
                },
                    error => {
                        console.log(error);
                        observador.next(false);
                        this.servicioGlobal.cambiarEstadoLoader(false);
                        observador.complete();
                    });
            });

        } else {
            this.servicioGlobal.cambiarEstadoLoader(true);
            return new Observable<boolean>(observador => {
                this.fireAuth.auth.onAuthStateChanged(
                    respuesta => {
                        console.log(respuesta);
                        if (respuesta == null) {
                            this.navegacion.navigate(['/usuarios/inicio-sesion']);
                            this.servicioGlobal.cambiarEstadoLoader(false);
                            observador.next(false);
                            observador.complete();
                        } else {
                            this.servicioGlobal.cambiarEstadoLoader(false);
                            observador.next(true);
                            observador.complete();
                        }
                    });
            });
        }
    }
}