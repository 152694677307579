import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { MzSidenavComponent, MzToastService } from 'ngx-materialize';
import { LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';

import { Privilegio } from "../../../entidades/privilegio";
import { GlobalService } from '../../../servicios/global.service';
import { PerfilesService } from "../../../servicios/perfiles.service";
import { Perfil } from 'src/app/entidades/perfil';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/entidades/usuario';

@Component({
  selector: 'app-barra-navegacion',
  templateUrl: './barra-navegacion.component.html',
  styleUrls: ['./barra-navegacion.component.css']
})
export class BarraNavegacionComponent implements OnInit, OnDestroy {

  @ViewChild('barraLateral', { static: true })
  barraLateral: MzSidenavComponent;

  sesionIniciada: boolean = false;
  informacionCargada: boolean = false;
  suscripcionSesion: Subscription;
  suscripcionDatos: Subscription;
  suscripcionConsulta: Subscription;
  suscripcionUsuario: Subscription;

  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '100%', // Starting top style attribute
    endingTop: '10%' // Ending top style attribute
  };

  privilegioAplicaciones: Privilegio = new Privilegio();
  privilegioUsuarios: Privilegio = new Privilegio();
  privilegioPerfiles: Privilegio = new Privilegio();
  privilegioOficinas: Privilegio = new Privilegio();

  /***********************************/
  // contenido
  /***********************************/

  privilegioBlogsDev: Privilegio = new Privilegio();
  privilegioFacturacionDev: Privilegio = new Privilegio();
  privilegioFacturacionQA: Privilegio = new Privilegio();
  privilegioFacturacionProd: Privilegio = new Privilegio();
  privilegioRollBitsDev: Privilegio = new Privilegio();
  privilegioRollBitsQA: Privilegio = new Privilegio();
  privilegioRollBitsProd: Privilegio = new Privilegio();
  privilegioCoordinadosDev: Privilegio = new Privilegio();
  privilegioCoordinadosProd: Privilegio = new Privilegio();
  privilegioSiemprePlusDev: Privilegio = new Privilegio();
  privilegioPrimeraPlusDev: Privilegio = new Privilegio();
  privilegioPrimeraPlusProd: Privilegio = new Privilegio();
  privilegioPrimeraPlusQA: Privilegio = new Privilegio();
  privilegioPaqueteriaDev: Privilegio = new Privilegio();
  privilegioPaqueteriaProd: Privilegio = new Privilegio();
  privilegioTTURDev: Privilegio = new Privilegio();
  privilegioTTURProd: Privilegio = new Privilegio();
  privilegioConectaGFA: Privilegio = new Privilegio();
  privilegioConectaGFAProd: Privilegio = new Privilegio();

  /***********************************/
  // configuracion
  /***********************************/

  privilegioConfiguracionBlogsDev: Privilegio = new Privilegio();
  privilegioConfiguracionFacturacionDev: Privilegio = new Privilegio();
  privilegioConfiguracionRollBitsDev: Privilegio = new Privilegio();
  privilegioConfiguracionRollBitsQA: Privilegio = new Privilegio();
  privilegioConfiguracionRollBitsProd: Privilegio = new Privilegio();
  privilegioConfiguracionCoordinadosDev: Privilegio = new Privilegio();
  privilegioConfiguracionCoordinadosProd: Privilegio = new Privilegio();
  privilegioConfiguracionSiemprePlusDev: Privilegio = new Privilegio();
  privilegioConfiguracionPrimeraPlusDev: Privilegio = new Privilegio();
  privilegioConfiguracionPrimeraPlusProd: Privilegio = new Privilegio();
  privilegioConfiguracionPrimeraPlusQA: Privilegio = new Privilegio();
  privilegioConfiguracionPaqueteriaDev: Privilegio = new Privilegio();
  privilegioConfiguracionPaqueteriaProd: Privilegio = new Privilegio();
  privilegioConfiguracionTTURDev: Privilegio = new Privilegio();
  privilegioConfiguracionTTURProd: Privilegio = new Privilegio();
  privilegioConfiguracionConectaGFA: Privilegio = new Privilegio();
  privilegioConfiguracionConectaGFAProd: Privilegio = new Privilegio();

  /***********************************/
  // operaciones
  /***********************************/

  privilegioOperacionesBlogsDev: Privilegio = new Privilegio();
  privilegioOperacionesRollBitsDev: Privilegio = new Privilegio();
  privilegioOperacionesRollBitsQA: Privilegio = new Privilegio();
  privilegioOperacionesRollBitsProd: Privilegio = new Privilegio();
  privilegioOperacionesCoordinadosDev: Privilegio = new Privilegio();
  privilegioOperacionesCoordinadosProd: Privilegio = new Privilegio();
  privilegioOperacionesSiemprePlusDev: Privilegio = new Privilegio();
  privilegioOperacionesPrimeraPlusDev: Privilegio = new Privilegio();
  privilegioOperacionesPrimeraPlusProd: Privilegio = new Privilegio();
  privilegioOperacionesPrimeraPlusQA: Privilegio = new Privilegio();
  privilegioOperacionesConectaGFA: Privilegio = new Privilegio();
  privilegioOperacionesConectaGFAProd: Privilegio = new Privilegio();

  /***********************************/
  // origenes-destinos
  /***********************************/

  privilegioOrigenesDestinosCoordinadosDev: Privilegio = new Privilegio();
  privilegioOrigenesDestinosCoordinadosProd: Privilegio = new Privilegio();
  privilegioOrigenesDestinosSiemprePlusDev: Privilegio = new Privilegio();
  privilegioOrigenesDestinosPrimeraPlusDev: Privilegio = new Privilegio();
  privilegioOrigenesDestinosPrimeraPlusProd: Privilegio = new Privilegio();
  privilegioOrigenesDestinosPrimeraPlusQA: Privilegio = new Privilegio();

  /***********************************/
  // sucursales
  /***********************************/

  privilegioSucursalesPaqueteriaDev: Privilegio = new Privilegio();
  privilegioSucursalesPaqueteriaProd: Privilegio = new Privilegio();
  privilegioSucursalesTTURDev: Privilegio = new Privilegio();
  privilegioSucursalesTTURProd: Privilegio = new Privilegio();

  /***********************************/
  // ciudades
  /***********************************/

  privilegioCiudadesPaqueteriaDev: Privilegio = new Privilegio();
  privilegioCiudadesPaqueteriaProd: Privilegio = new Privilegio();
  privilegioCiudadesTTURDev: Privilegio = new Privilegio();
  privilegioCiudadesTTURProd: Privilegio = new Privilegio();

  /***********************************/
  // estados
  /***********************************/

  privilegioEstadosPaqueteriaDev: Privilegio = new Privilegio();
  privilegioEstadosPaqueteriaProd: Privilegio = new Privilegio();
  privilegioEstadosTTURDev: Privilegio = new Privilegio();
  privilegioEstadosTTURProd: Privilegio = new Privilegio();

  /***********************************/
  // autobuses
  /***********************************/

  privilegioAutobusesTTURDev: Privilegio = new Privilegio();
  privilegioAutobusesTTURProd: Privilegio = new Privilegio();

  /***********************************/
  // facturas
  /***********************************/

  privilegioFacturasFacturacionAdministracionDev: Privilegio = new Privilegio();
  privilegioFacturasFacturacionAdministracionQA: Privilegio = new Privilegio();
  privilegioFacturasFacturacionAdministracionProd: Privilegio = new Privilegio();

  /***********************************/
  // reacturas
  /***********************************/

  privilegioRefacturasDev: Privilegio = new Privilegio();

  /***********************************/
  // nominas
  /***********************************/

  privilegioNominasFacturacionAdministracionDev: Privilegio = new Privilegio();
  privilegioNominasFacturacionAdministracionQA: Privilegio = new Privilegio();
  privilegioNominasFacturacionAdministracionProd: Privilegio = new Privilegio();

  /***********************************/
  // reportes
  /***********************************/

  privilegioReportesFacturacionAdministracionDev: Privilegio = new Privilegio();
  privilegioReportesFacturacionAdministracionQA: Privilegio = new Privilegio();
  privilegioReportesFacturacionAdministracionProd: Privilegio = new Privilegio();

  /***********************************/
  // recuperaciones
  /***********************************/

  privilegioRecuperacionPaqueteriaCartaPorteDev: Privilegio = new Privilegio();
  privilegioRecuperacionEnviosCartaPorteDev: Privilegio = new Privilegio();
  privilegioRecuperacionPaqueteriaCartaPorteProd: Privilegio = new Privilegio();
  privilegioRecuperacionEnviosCartaPorteProd: Privilegio = new Privilegio();
  privilegioRecuperacionPaqueteriaCartaPorteQA: Privilegio = new Privilegio();
  privilegioRecuperacionEnviosCartaPorteQA: Privilegio = new Privilegio();

  /***********************************/
  // catalogos
  /***********************************/

  privilegioCatalogosDev: Privilegio = new Privilegio();
  privilegioCatalogosQA: Privilegio = new Privilegio();
  privilegioCatalogosProd: Privilegio = new Privilegio();

  perfilAnfitrion: Perfil = new Perfil();

  usuarioAnfitrion: Usuario = new Usuario();
  saludo: string = '';

  constructor(private fireAuth: AngularFireAuth,
    private servicioGlobal: GlobalService,
    private navegacion: Router,
    private servicioToast: MzToastService,
    private servicioPerfiles: PerfilesService,
    private servicioUsuarios: UsuariosService,
    private almacen: LocalStorageService) { }

  ngOnDestroy(): void {
    try {
      this.almacen.clear();
      this.fireAuth.auth.signOut()
      this.suscripcionSesion.unsubscribe();
    } catch (error) { }
  }

  async ngOnInit() {
    await this.cargarUsuarioLocal();
    await this.cargarPrivilegios();
    this.bienvenida();
  }

  bienvenida() {
    let myDate = new Date();
    let hrs = myDate.getHours();

    if (hrs < 12)
      this.saludo = 'Buenos Dias';
    else if (hrs >= 12 && hrs <= 17)
      this.saludo = 'Buenas Tardes';
    else if (hrs >= 17 && hrs <= 24)
      this.saludo = 'Buenas Noches';

  }

  cargarPerfilLocal = async (): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      this.servicioGlobal.cambiarEstadoLoader(true);
      this.suscripcionConsulta = this.servicioPerfiles.cargarPerfilLocal().subscribe(
        suscribe => {
          if (suscribe) {
            this.servicioGlobal.cambiarEstadoInformacion(true);
          }
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        },
        error => {
          console.log(error)
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        });
    });
  }

  cargarUsuarioLocal = async (): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      this.servicioGlobal.cambiarEstadoLoader(true);
      this.suscripcionUsuario = this.servicioUsuarios.cargarUsuarioLocal().subscribe(
        datos => {
          this.usuarioAnfitrion = datos;
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        },
        error => {
          console.log(error)
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        });
    });
  }

  buscarPrivilegios = async (): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      let suscripcionPerfiles: Subscription;
      suscripcionPerfiles = this.servicioPerfiles.cargarPerfilLocal().subscribe(
        perfil => {

          if (perfil) {
            console.log("se cargo perfil");
            this.perfilAnfitrion = perfil
            console.log("perfilBarra: ", perfil);

            perfil.privilegios.forEach(privilegio => {

              switch (privilegio.modulo) {

                case 'aplicaciones': {
                  this.privilegioAplicaciones = privilegio;
                  break;
                };
                case 'usuarios': {
                  this.privilegioUsuarios = privilegio;
                  break;
                };
                case 'perfiles': {
                  this.privilegioPerfiles = privilegio;
                  break;
                };
                case 'oficinas': {
                  this.privilegioOficinas = privilegio;
                  break;
                };

                /*************/
                /* Contenido */
                /************/

                case 'edicionContenidoCoordinadosDev': {
                  this.privilegioCoordinadosDev = privilegio;
                  break;
                };
                case 'edicionContenidoCoordinadosProd': {
                  this.privilegioCoordinadosProd = privilegio;
                  break;
                };
                case 'edicionContenidoSiemprePlusDev': {
                  this.privilegioSiemprePlusDev = privilegio;
                  break;
                };
                case 'edicionContenidoPrimeraPlusDev': {
                  this.privilegioPrimeraPlusDev = privilegio;
                  break;
                };
                case 'edicionContenidoPrimeraPlusProd': {
                  this.privilegioPrimeraPlusProd = privilegio;
                  break;
                };
                case 'edicionContenidoPrimeraPlusQA': {
                  this.privilegioPrimeraPlusQA = privilegio;
                  break;
                };
                case 'edicionContenidoBlogsDev': {
                  this.privilegioBlogsDev = privilegio;
                  break;
                };
                case 'edicionContenidoFacturacionEmisionDev': {
                  this.privilegioFacturacionDev = privilegio;
                  break;
                };
                case 'edicionContenidoFacturacionEmisionQA': {
                  this.privilegioFacturacionQA = privilegio;
                  break;
                };
                case 'edicionContenidoFacturacionEmisionProd': {
                  this.privilegioFacturacionProd = privilegio;
                  break;
                };
                case 'edicionContenidoRollBitsDev': {
                  this.privilegioRollBitsDev = privilegio;
                  break;
                };
                case 'edicionContenidoRollBitsQA': {
                  this.privilegioRollBitsQA = privilegio;
                  break;
                };
                case 'edicionContenidoRollBitsProd': {
                  this.privilegioRollBitsProd = privilegio;
                  break;
                };

                case 'edicionContenidoConectaGFA': {
                  this.privilegioConectaGFA = privilegio;
                  break;
                };

                case 'edicionContenidoConectaGFAProd': {
                  this.privilegioConectaGFAProd = privilegio;
                  break;
                };
                case 'edicionContenidoPaqueteriaDev': {
                  this.privilegioPaqueteriaDev = privilegio;
                  break;
                };
                case 'edicionContenidoPaqueteriaProd': {
                  this.privilegioPaqueteriaProd = privilegio;
                  break;
                };
                case 'edicionContenidoTTURDev': {
                  this.privilegioTTURDev = privilegio;
                  break;
                };
                case 'edicionContenidoTTURProd': {
                  this.privilegioTTURProd = privilegio;
                  break;
                };

                /*****************/
                /* Configuración */
                /****************/

                case 'configuracionCoordinadosDev': {
                  this.privilegioConfiguracionCoordinadosDev = privilegio;
                  break;
                };
                case 'configuracionCoordinadosProd': {
                  this.privilegioConfiguracionCoordinadosProd = privilegio;
                  break;
                };
                case 'configuracionSiemprePlusDev': {
                  this.privilegioConfiguracionSiemprePlusDev = privilegio;
                  break;
                };
                case 'configuracionPrimeraPlusDev': {
                  this.privilegioConfiguracionPrimeraPlusDev = privilegio;
                  break;
                };
                case 'configuracionPrimeraPlusProd': {
                  this.privilegioConfiguracionPrimeraPlusProd = privilegio;
                  break;
                };
                case 'configuracionPrimeraPlusQA': {
                  this.privilegioConfiguracionPrimeraPlusQA = privilegio;
                  break;
                };
                case 'configuracionBlogsDev': {
                  this.privilegioConfiguracionBlogsDev = privilegio;
                  break;
                };
                case 'configuracionFacturacionEmisionDev': {
                  this.privilegioConfiguracionFacturacionDev = privilegio;
                  break;
                };
                case 'configuracionRollBitsDev': {
                  this.privilegioConfiguracionRollBitsDev = privilegio;
                  break;
                };
                case 'configuracionRollBitsQA': {
                  this.privilegioConfiguracionRollBitsQA = privilegio;
                  break;
                };
                case 'configuracionRollBitsProd': {
                  this.privilegioConfiguracionRollBitsProd = privilegio;
                  break;
                };

                case 'configuracionConectaGFA': {
                  this.privilegioConfiguracionConectaGFA = privilegio;
                  break;
                };

                case 'configuracionConectaGFAProd': {
                  this.privilegioConfiguracionConectaGFAProd = privilegio;
                  break;
                };
                case 'configuracionPaqueteriaDev': {
                  this.privilegioConfiguracionPaqueteriaDev = privilegio;
                  break;
                };
                case 'configuracionPaqueteriaProd': {
                  this.privilegioConfiguracionPaqueteriaProd = privilegio;
                  break;
                };
                case 'configuracionTTURDev': {
                  this.privilegioConfiguracionTTURDev = privilegio;
                  break;
                };
                case 'configuracionTTURProd': {
                  this.privilegioConfiguracionTTURProd = privilegio;
                  break;
                };

                /*****************/
                /* Operaciones */
                /****************/

                case 'operacionesCoordinadosDev': {
                  this.privilegioOperacionesCoordinadosDev = privilegio;
                  break;
                };
                case 'operacionesCoordinadosProd': {
                  this.privilegioOperacionesCoordinadosProd = privilegio;
                  break;
                };
                case 'operacionesSiemprePlusDev': {
                  this.privilegioOperacionesSiemprePlusDev = privilegio;
                  break;
                };
                case 'operacionesPrimeraPlusDev': {
                  this.privilegioOperacionesPrimeraPlusDev = privilegio;
                  break;
                };
                case 'operacionesPrimeraPlusProd': {
                  this.privilegioOperacionesPrimeraPlusProd = privilegio;
                  break;
                };
                case 'operacionesPrimeraPlusQA': {
                  this.privilegioOperacionesPrimeraPlusQA = privilegio;
                  break;
                };
                case 'operacionesBlogsDev': {
                  this.privilegioOperacionesBlogsDev = privilegio;
                  break;
                };
                case 'operacionesRollBitsDev': {
                  this.privilegioOperacionesRollBitsDev = privilegio;
                  break;
                };
                case 'operacionesRollBitsQA': {
                  this.privilegioOperacionesRollBitsQA = privilegio;
                  break;
                };
                case 'operacionesRollBitsProd': {
                  this.privilegioOperacionesRollBitsProd = privilegio;
                  break;
                };

                case 'operacionesConectaGFA': {
                  this.privilegioOperacionesConectaGFA = privilegio;
                  break;
                };

                case 'operacionesConectaGFAProd': {
                  this.privilegioOperacionesConectaGFAProd = privilegio;
                  break;
                };

                /********************/
                /* Origenes Destinos */
                /*******************/

                case 'origenesDestinosCoordinadosDev': {
                  this.privilegioOrigenesDestinosCoordinadosDev = privilegio;
                  break;
                };
                case 'origenesDestinosCoordinadosProd': {
                  this.privilegioOrigenesDestinosCoordinadosProd = privilegio;
                  break;
                };
                case 'origenesDestinosSiemprePlusDev': {
                  this.privilegioOrigenesDestinosSiemprePlusDev = privilegio;
                  break;
                };
                case 'origenesDestinosPrimeraPlusDev': {
                  this.privilegioOrigenesDestinosPrimeraPlusDev = privilegio;
                  break;
                };
                case 'origenesDestinosPrimeraPlusProd': {
                  this.privilegioOrigenesDestinosPrimeraPlusProd = privilegio;
                  break;
                };
                case 'origenesDestinosPrimeraPlusQA': {
                  this.privilegioOrigenesDestinosPrimeraPlusQA = privilegio;
                  break;
                };

                /************/
                /* Sucursales */
                /************/

                case 'sucursalesPaqueteriaDev': {
                  this.privilegioSucursalesPaqueteriaDev = privilegio;
                  break;
                };

                case 'sucursalesPaqueteriaProd': {
                  this.privilegioSucursalesPaqueteriaProd = privilegio;
                  break;
                };
                case 'sucursalesTTURDev': {
                  this.privilegioSucursalesTTURDev = privilegio;
                  break;
                };
                case 'sucursalesTTURProd': {
                  this.privilegioSucursalesTTURProd = privilegio;
                  break;
                };

                /************/
                /* Ciudades */
                /************/

                case 'ciudadesPaqueteriaDev': {
                  this.privilegioCiudadesPaqueteriaDev = privilegio;
                  break;
                };
                case 'ciudadesPaqueteriaProd': {
                  this.privilegioCiudadesPaqueteriaProd = privilegio;
                  break;
                };
                case 'ciudadesTTURDev': {
                  this.privilegioCiudadesTTURDev = privilegio;
                  break;
                };
                case 'ciudadesTTURProd': {
                  this.privilegioCiudadesTTURProd = privilegio;
                  break;
                };

                /************/
                /* Estados */
                /************/

                case 'estadosPaqueteriaDev': {
                  this.privilegioEstadosPaqueteriaDev = privilegio;
                  break;
                };
                case 'estadosPaqueteriaProd': {
                  this.privilegioEstadosPaqueteriaProd = privilegio;
                  break;
                };
                case 'estadosTTURDev': {
                  this.privilegioEstadosTTURDev = privilegio;
                  break;
                };
                case 'estadosTTURProd': {
                  this.privilegioEstadosTTURProd = privilegio;
                  break;
                };

                /*************/
                /* Autobuses */
                /*************/

                case 'autobusesTTURDev': {
                  this.privilegioAutobusesTTURDev = privilegio;
                  break;
                };
                case 'autobusesTTURProd': {
                  this.privilegioAutobusesTTURProd = privilegio;
                  break;
                };

                /*******************/
                /* facturas        */
                /*******************/

                case 'facturasFacturacionAdministracionDev': {
                  this.privilegioFacturasFacturacionAdministracionDev = privilegio;
                  break;
                };
                case 'facturasFacturacionAdministracionQA': {
                  this.privilegioFacturasFacturacionAdministracionQA = privilegio;
                  break;
                };
                case 'facturasFacturacionAdministracionProd': {
                  this.privilegioFacturasFacturacionAdministracionProd = privilegio;
                  break;
                };

                /*******************/
                /* refacturas      */
                /*******************/

                case 'refacturasDev': {
                  this.privilegioRefacturasDev = privilegio;
                  break;
                };

                /*******************/
                /* nominas         */
                /*******************/

                case 'nominasFacturacionAdministracionDev': {
                  this.privilegioNominasFacturacionAdministracionDev = privilegio;
                  break;
                };
                case 'nominasFacturacionAdministracionQA': {
                  this.privilegioNominasFacturacionAdministracionQA = privilegio;
                  break;
                };
                case 'nominasFacturacionAdministracionProd': {
                  this.privilegioNominasFacturacionAdministracionProd = privilegio;
                  break;
                };

                /*******************/
                /* reportes        */
                /*******************/

                case 'reportesFacturacionAdministracionDev': {
                  this.privilegioReportesFacturacionAdministracionDev = privilegio;
                  break;
                };
                case 'reportesFacturacionAdministracionQA': {
                  this.privilegioReportesFacturacionAdministracionQA = privilegio;
                  break;
                };
                case 'reportesFacturacionAdministracionProd': {
                  this.privilegioReportesFacturacionAdministracionProd = privilegio;
                  break;
                };

                /****************************/
                /* Recuperacion Paqueteria  */
                /****************************/

                case 'recuperacionPaqueteriaCartaPorteDev': {
                  this.privilegioRecuperacionPaqueteriaCartaPorteDev = privilegio;
                  break;
                }

                case 'recuperacionPaqueteriaCartaPorteProd': {
                  this.privilegioRecuperacionPaqueteriaCartaPorteProd = privilegio;
                  break;
                }

                case 'recuperacionPaqueteriaCartaPorteQA': {
                  this.privilegioRecuperacionPaqueteriaCartaPorteQA = privilegio;
                  break;
                }

                /****************************/ 
                /* Recuperacion Envíos      */
                /****************************/

                case 'recuperacionEnviosCartaPorteDev': {
                  this.privilegioRecuperacionEnviosCartaPorteDev = privilegio;
                  break;
                }

                case 'recuperacionEnviosCartaPorteProd': {
                  this.privilegioRecuperacionEnviosCartaPorteProd = privilegio;
                  break;
                }

                case 'recuperacionEnviosCartaPorteQA': {
                  this.privilegioRecuperacionEnviosCartaPorteQA = privilegio;
                  break;
                }

                /****************************/ 
                /* Catalogos                */
                /****************************/

                case 'catalogosDev': {
                  this.privilegioCatalogosDev = privilegio;
                  break;
                };

                case 'catalogosQA': {
                  this.privilegioCatalogosQA = privilegio;
                  break;
                };

                case 'catalogosProd': {
                  this.privilegioCatalogosProd = privilegio;
                  break;
                };

                default: {
                  console.log("[Privilegio Modulo]", privilegio.modulo);
                  console.log("entró en el default de los privilegios");
                  break;
                };
              }

            });
          }
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        },
        error => {
          console.log(error)
          this.servicioGlobal.cambiarEstadoLoader(false);
          resolve(true);
        });
    });
  }

  cargarPrivilegios = async (): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {

      this.servicioGlobal.cambiarEstadoLoader(true);
      let suscripcion: Subscription;

      suscripcion = this.servicioGlobal.informacionActiva.subscribe(
        async suscripcion => {
          this.informacionCargada = suscripcion;
          await this.cargarPerfilLocal();
          this.servicioGlobal.cambiarEstadoLoader(false);
        },
        error => {
          console.log(error);
          this.servicioGlobal.cambiarEstadoLoader(false);
        });

      this.servicioGlobal.cambiarEstadoLoader(true);
      this.suscripcionSesion = this.servicioGlobal.sesionActiva.subscribe(
        async respuesta => {
          this.sesionIniciada = respuesta;
          if (respuesta) {
            this.suscripcionDatos = this.servicioGlobal.informacionActiva.subscribe(
              async valor => {
                console.log("se inicio sesion?", valor);
                if (valor) {
                  await this.buscarPrivilegios();
                }

                resolve(true);
              },
              error => {
                console.log(error)
                this.servicioGlobal.cambiarEstadoLoader(false);
                resolve(true);
              }
            );
          }
        },
        error => {
          console.log(error);
          this.servicioGlobal.cambiarEstadoLoader(false);
        }
      )

    });
  }

  cerrarSesion() {
    this.servicioGlobal.cambiarEstadoLoader(true);
    this.servicioUsuarios.eliminarUsuarioLocal();
    this.almacen.clear();
    this.fireAuth.auth.signOut()
      .then(respuesta => {
        console.log(respuesta);
        this.barraLateral.opened = false;
        this.servicioGlobal.cambiarEstadoInformacion(false);
        this.servicioToast.show('Se ha cerrado sesión', 4000, 'orange darken-1');
        /* this.navegacion.navigate(['/usuarios/inicio-sesion']); */
        this.servicioGlobal.cambiarEstadoLoader(false);
      })
      .catch(error => {
        console.log(error);
        this.servicioGlobal.cambiarEstadoLoader(false);
      });
  }

}
